.sidebar-container {
    width: 250px;
    background-color: #EBF0F5; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: #5B86A9; 
    font-family: 'Arial', sans-serif;
    transition: transform 0.3s ease-in-out;
}

.back-to-chat{
    visibility: hidden;
    position: absolute;
}

.mobile-btn{
    visibility: hidden;
    position: absolute;
}

@media (max-width: 768px) {
    .sidebar-container {
        transform: translateX(-100%);
        position: fixed;
        top: 50px;
        left: 0;
        height: calc(100% - 50px); 
        z-index: 50;
        width: 100%; 
        overflow-y: auto; 
    }

    .mobile-btn{
        visibility: visible;
        position: relative;
    }
    

    .sidebar-container.open {
        transform: translateX(0);
    }

    .back-to-chat{
        visibility: visible;
        position: relative;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: #779BB9;
    }

    .back-to-chat img {
        width: 20px; 
        height: 20px; 
    }

    .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 2px solid #FFFFFF; 
        font-weight: bold;
    }
    
}
.sidebar-header {
    padding: 20px;
    border-bottom: 2px solid #FFFFFF; 
    font-weight: bold;
}

.conversation-group {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
  }

  .conversation-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }

  .group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 20px;
    font-weight: bold;
}

.group-header-popular-questions{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    cursor: pointer;
    padding-left: 10px;
    padding-top: 50px;
    font-weight: bold;
}


.conversation:last-of-type {
    margin-bottom: 20px; 
}

.group-header span:first-child {
    margin-right: 4px; 
}

.group-count {
    padding-left: 0px; 
}

  .conversation-count {
    background-color: #cccccc;
    border-radius: 50%;
    font-size: 0.8em;
  }

.trash-icon {
    cursor: pointer; 
    color: grey; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px; 
    width: 24px; 

    &:hover {
        color: red; 
    }
}

.new-conversation-btn-container {
    display: flex;
    justify-content: center; 
    padding: 10px 0; 
}


.new-conversation-btn {
    padding: 10px 15px;
    width: 85%; 
    background-color: #FFFFFF; 
    color: #779BB9; 
    border: none; 
    cursor: pointer;
    border-radius: 20px; 
    text-align: center;
    font-size: 14px; 
    font-weight: 500; 
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, box-shadow 0.3s;
    gap: 10px;
}

.new-conversation-btn img {
    flex-shrink: 0; 
    height: auto;
    width: 20px;
}

.new-conversation-btn:hover {
    background-color: #dddddd; 
}

.threads-section {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.popular-question {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-right: 10px;
    padding: 10px 15px; 
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 50px;
    background-color: transparent;  
    color: #5B86A9;
    font-family: 'Roboto';
    margin-top: 8px;
    font-size: 12px;
    position: relative;
}

.popular-question:hover {
    background-color: #F5F7FA;  
}

.copy-icon {
    cursor: pointer;
    margin-left: 10px;
    visibility: hidden; 
}

.popular-question:hover .copy-icon {
    visibility: visible;
}

.conversation {
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding: 10px 15px; 
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 50px;
    background-color: transparent;  
    color: #5B86A9;
    font-family: 'Roboto';
    margin-top:8px;
    font-size: 12px;
    position: relative;
}

.conversation:hover {
    background-color: #F5F7FA;  
}

.conversation.active-conversation {
    background-color: #FFFFFF;
    display: block; 
}


.sidebar-footer {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.default-footer-button {
    padding: 1px;
    background-color: #444654; 
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s;
}

.schedule-btn {
    padding: 10px 20px; 
    background-color: #FFFFFF; 
    color: #779BB9;
    border: none; 
    cursor: pointer;
    border-radius: 20px; 
    text-align: center;
    font-size: 14px; 
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px; 
    transition: background-color 0.3s, box-shadow 0.3s; 
}

.schedule-btn:hover {
    background-color: #dddddd; 
}

.mobile-btn {
    padding: 10px 20px; 
    background-color: #FFFFFF; 
    color: #779BB9;
    border: none; 
    cursor: pointer;
    border-radius: 20px; 
    text-align: center;
    font-size: 14px;
    font-weight: 500; 
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    transition: background-color 0.3s, box-shadow 0.3s; 
    white-space: nowrap;
}

.mobile-btn:hover {
    background-color: #dddddd; 
}


.tax-calculator-btn {
    padding: 10px 20px; 
    background-color: #FFFFFF; 
    color: #779BB9;
    border: none; 
    cursor: pointer;
    border-radius: 20px; 
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    transition: background-color 0.3s, box-shadow 0.3s; 
    white-space: nowrap;
}

.tax-calculator-btn:hover {
    background-color: #dddddd; 
}

.more-icon-container {
    position: absolute; 
    right: 10px; 
    top: 55%;
    transform: translateY(-50%); 
    cursor: pointer;
}

  .more-icon-container img {
    padding: 3px;
    padding-left: 15px; 
    cursor: pointer;
}

  
.dropdown-menu {
    position: absolute;
    right: 10px; 
    top: 100%; 
    width: 150px; 
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    overflow: hidden;
    z-index: 100;
}

  
.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background-color: #f0f0f0;
}
