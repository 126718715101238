.table-scroll {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 50px;
}

.question-table-scroll {
    max-height: 300px;
}

.kga-table-scroll {
    max-height: 400px; 
}

.table-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.admin-view-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-group {
    display: flex;
    gap: 10px;
}

.admin-view-table td.reason-cell {
    white-space: normal; 
    word-wrap: break-word; 
}



.header-button {
    padding: 5px 15px; 
    border-radius: 15px;
    background-color: white;
    color: #5B86A9;
    border: 2px solid #5B86A9;
    cursor: pointer;
    outline: none;
    font-size: 14px; 
}

.header-button:hover {
    background-color: #5B86A9; 
    color: white; 
}



.side-by-side-tables {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
}

.admin-view-container {
    font-family: 'Arial', sans-serif;
    color: #5B86A9;
    background-color: #EBF0F5;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.admin-view-table {
    width: 100%;
    border-collapse: collapse;
}

.admin-view-table th {
    background-color: #4A90E2;
    color: #FFFFFF; 
}


.admin-view-table th, .admin-view-table td {
    padding: 10px;
    border-bottom: 1px solid #AEBFCE;
    text-align: left;
}

.admin-view-table tr:hover {
    background-color: #D6DBE0;
}

.admin-view-table a {
    color: #008cff;
    text-decoration: none;
    transition: color 0.3s;
}

.admin-view-table a:hover {
    color: #4eafff;
}

@media (max-width: 768px) {
    .admin-view-container {
        padding: 10px;
    }

    .admin-view-table th, .admin-view-table td {
        padding: 5px;
    }

    .side-by-side-tables {
        flex-direction: column;
    }
}

.passcode-verification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #EEF1F4;
}

.passcode-verification input {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #5B86A9; 
    margin-right: 10px;
    font-size: 16px;
    color: #5B86A9;
    background-color: #EBF0F5;
}

.passcode-verification input:focus {
    outline: none; 
}
.passcode-verification input::placeholder {
    color: #5B86A9;
}

.passcode-verification button {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #5B86A9;
    background-color: #EBF0F5;
    border: 2px solid #5B86A9; 
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.passcode-verification button:hover {
    background-color: #c6cbcf;
    color: #5B86A9;
}

