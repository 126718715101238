.sc-message {
    display: flex;
    align-items: flex-start; 
    width: 60%;
    min-width: 50%;
    min-height: 50px;
    border-radius: 30px;
    margin: 2px 0;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #5B86A9;
    border: 5px solid white;
    overflow-wrap: break-word;
    position: relative;
}

.sc-navbar {
    height: 17px;
    background-image: linear-gradient(to right, #98DB48, #E67FD4, #935CDD);
    padding: 15px 15px;
    font-size: 18px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.sc-navbar-title {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;
}

.sc-navbar-title:hover {
    color: #c4c4c4;
}

.sc-messages-section {
    flex: 1;
    overflow-y: scroll; 
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    scroll-behavior: smooth;
    background-color:transparent;
    max-height: calc(100vh - 120px);
}

.icons-container {
    display: flex;
    align-items: center;
    margin-top: 25px; 
    padding-left: 30px;
}

.sc-icon-thumb-up, .sc-icon-thumb-down, .sc-comment {
    margin-right: 5px; 
}


.sc-message-wrapper {
    display: flex;
    align-items: center; 
    justify-content: flex-end; 
    width: 100%;
    padding: 10px 0;
}

.sc-message-wrapper.assistant .sc-message {
    margin-left: auto; 
    margin-bottom: 35px;
}

.sc-message-wrapper.user .sc-message {
    margin-right: auto;
}

.sc-message-content {
    display: flex;
    align-items: center;
    width: 100%;
}


.sc-profile-square {
    top: 0; 
    left: 0; 
    transform: translate(-5%, -5%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 5px solid white; 
    flex-shrink: 0;
}


.sc-user .sc-profile-square, 
.sc-assistant .sc-profile-square {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.white-links a {
    color: white;
    text-decoration: underline;
}

.sc-feedback-and-comment {
    display: flex;
    align-items: center; 
    margin-top: 5px; 
    padding-bottom: 25px;
}
.sc-comment {
    color: #5B86A9;
    margin-left: 10px;
}

.sc-highlighted-green {
    color: green !important;
}

.sc-highlighted-red {
    color: red !important;
}

.sc-messages-section::-webkit-scrollbar {
    display: none; 
}
