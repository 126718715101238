.cf-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cf-modal-content {
    background-color: #EBF0F5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 50%;
    max-width: 600px;
  }
  
  .cf-contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .cf-contact-form-container h1 {
    color: #5B86A9;
    text-align: center;
    width: 100%;
  }
  
  .cf-demo-message, .cf-submission-status {
    color: #5B86A9;
    width: 100%;
    text-align: center;
  }

  .cf-demo-button{
    background-color: #ffffff;
    color: #5B86A9;
    border: 2px solid #5B86A9;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 30px;
  }
  
  .cf-contact-form-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .cf-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    margin-left:15%;
  }
  
  .cf-input-group label {
    margin-right: 10px;
    width: 20%;
    font-weight: bold;
    color: #5B86A9;
  }
  
  .cf-input-group input {
    width: 60%; 
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .cf-form-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
  
  .cf-close-button, .cf-submit-button {
    background-color: #ffffff;
    color: #5B86A9;
    border: 2px solid #5B86A9;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 30px;
  }
  
  .cf-close-button:hover, .cf-submit-button:hover {
    background-color: #e7e7e7;
    color: #5B86A9;
  }

  @media (max-width: 768px) {
  
    .cf-modal-content {
      width: 90%;

    }
  }
  