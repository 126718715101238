.message {
    width: 60%;
    min-width: 50%;
    min-height: 50px;
    padding-left: 60px;
    padding-right: 25px;
    border-radius: 30px;
    margin: 2px 0;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
    display: inline-block;
    overflow-wrap: break-word;
    position: relative;
    background-color: #ffffff;
    color: #5B86A9;
    border: 5px solid white;
}

.message-calendar-button {
    background-color: white;
    border: 1px solid #ccc; 
    color: #5B86A9; 
    padding: 6px 12px;
    border-radius: 20px; 
    display: inline-flex; 
    align-items: center;
    gap: 8px; 
    cursor: pointer;
    text-decoration: none; 
    font-size: 14px; 
    transition: background-color 0.3s, border-color 0.3s;
}

.message-calendar-button:hover {
    background-color: #f0f0f0; 
    border-color: #bbb; 
}


.grey-bottom-section {
    position: absolute;
    width: calc(100% + 10px);
    left: -5px;
    bottom: -50px; 
    background-color: #D9E3EC;
    border-radius: 0 0 25px 25px;
    height: 70px;
    z-index: -1;
    display: flex;
    align-items: center; 
}

.icons-container {
    display: flex;
    align-items: center;
    margin-top: 25px; 
    padding-left: 30px;
}

.icon-thumb-up, .icon-thumb-down, .icon-copy {
    cursor: pointer;
    margin-right: 20px;
}

.icon-copy {
    margin-right: 0;
}

.message-wrapper {
    display: flex;
    width: 100%;
    padding: 10px 0;
}

.message-wrapper.assistant .message {
    margin-left: auto; 
    margin-bottom: 45px;
}

.message-wrapper.user .message {
    margin-right: auto;
}

.message-content {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
}

.profile-square {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    right: auto;
    left: -3px;
    top: -3px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    border: 5px solid white; 
}


.user .profile-square, 
.assistant .profile-square {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.white-links a {
    color: white;
    text-decoration: underline;
}

.thinking-animation {
    display: inline-block;
    margin-left: 4px;
    padding-top: 10px;
}

.thinking-animation::after {
    content: '';
    animation: ellipsis 1.5s infinite;
}

.icon-comment {
    margin-left: 5px;
    transition: color 0.2s; 
    cursor: pointer;
    margin-right: 20px;
}

.icon-comment.show-comment {
    opacity: 1;
}


.icon-comment.commented {
    color: yellow !important; 
}


.icon-comment:hover {
    color: yellow; 
}

.icon-thumb-up, .icon-thumb-down, .icon-copy, .icon-comment {
    cursor: pointer;
    margin-right: 20px;color: #7D97AF; 
}

.icon-copy {
    margin-right: 0;
}

.icon-thumb-up:hover, .icon-thumb-up.highlighted {
    color: green;
}

.icon-thumb-down:hover, .icon-thumb-down.highlighted {
    color: red;
}


.icon-copy:hover {
    color: #007bff; 
}