.top-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFDF5;
  color: #000;
  padding: 0px 20px;
  height: 70px;
}

.nav-logo {
  height: 30px; 
  width: auto; 
  cursor: pointer;
  padding-left: 30px;
}


.nav-title {
  font-size: 24px; 
  font-weight: bold; 
}

.nav-items a {
  color: #000; 
  text-decoration: none;
  padding: 10px 15px;
  font-size: 16px; 
  margin-right: 20px; 
}

.nav-items a:hover {
  color: #929292;
}

.get-started {
  background-color: #FFF14B; 
  border-radius: 50px; 
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid black;
  color: #000; 
  text-decoration: none; 
  transition: background-color 0.3s, border-radius 0.3s; 
  margin-right: 25px;
  cursor: pointer;
}

.get-started:hover {
  background-color: #FAB71E; 
  border-radius: 10px; 
  padding: 10px 20px;
  border: 1px solid black;
  color: #000; 
  text-decoration: none; 
  transition: background-color 0.3s, border-radius 0.3s; 
}

.hamburger-menu {
  visibility: hidden;
}

@media (max-width: 768px) {
  .get-started {
    padding: 8px 16px; 
    font-size: 14px;
    margin-right: 10px; 
    display: inline-block; 
  }

  .nav-logo {
    padding-left: 5px;
  }
  
}

@media (max-width: 768px) {
  .top-nav-bar {
    padding: 0 10px; 
    height: 60px; 
  }
}

