.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.feedback-modal-container {
    background-color: #EBF0F5;
    color: #5B86A9;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 30%; 
    max-width: 600px; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback-modal-header {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    width: 100%; 
}

.feedback-modal-textarea {
    width: 100%; 
    height: 150px; 
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #5B86A9;
    background-color: #ffffff;
    color: #5B86A9;
    resize: none; 
    overflow-y: auto; 
    font-family: 'Roboto', sans-serif; 
    font-size: 16px;
}


.feedback-modal-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.feedback-modal-button {
    padding: 10px 20px;
    border: 1px solid #5B86A9;
    border-radius: 5px;
    background-color: #ffffff;
    color: #5B86A9;
    cursor: pointer;
    transition: background-color 0.3s;
    flex: 1;
    margin-right: 10px;
    font-size: 16px;
}

.feedback-modal-button:last-child {
    margin-right: 0;
}

.feedback-modal-button:hover {
    background-color: #b8b8b8;
}

.feedback-thumbs-up {
    border: 5px solid #73A636;
}

.feedback-thumbs-down {
    border: 5px solid #E47E7E;
}

@media (max-width: 768px){
    .feedback-modal-container {
        width: 90%; 
        max-width: none; 
    }
}
