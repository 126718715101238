body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Add 'Roboto' at the front */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  background-color: rgba(192, 216, 238, 0.12);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  body {
      font-size: 16px; /* Adjust as needed */
  }
}
