.app-container {
  display: flex;
  height: calc(100vh - 70px); 
  width: 100vw;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.sidebar-toggle-btn {
visibility: hidden;
position: absolute;
}


@media (max-width: 768px) {
  .sidebar-toggle-btn {
    position: fixed;
    visibility: visible;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 20px;
    border: 0px solid transparent; 
    background-color: transparent; 
    color: black;
    padding: 5px; 
    z-index: 1000; 
  }
}

@keyframes cyclingDots {
  0%, 100% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

.responding::after {
  content: '';
  animation: cyclingDots 1.2s linear infinite;
}

.top-nav, .hamburger-btn {
  display: none;
}



.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  background-image: #F7FAFD;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #FFF;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  color: #FFF;
  font-size: 18px;
  margin-left: 15px; 
  animation: blink 1.2s infinite alternate;
}
