.summary-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #343541;
    height: 100vh;
}

.summary-navbar {
    height: 60px;
    width: 100%;
    background-color: #444654;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #FFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
}


.summary-box {
    background-color: #444654;
    padding: 30px;
    width: 70%; 
    border-radius: 8px;
    color: white;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.summary-box p {
    word-wrap: break-word;
    font-size: 18px;
}
