
.chat-welcome-message {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 95%;
    font-family: 'Roboto', sans-serif;
}

.custom-h1 {
    font-size: 28px; 
    font-weight: 700; 
    color: #546388;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: -10px;
}

.custom-h1-bold {
    font-size: 28px; 
    font-weight: 900; 
    color: #546388;
    font-family: 'Montserrat', sans-serif;
}

.welcome-message {
    color: #5D82A0;
    font-size: 18px;    
    line-height: 2.0;
    margin-bottom: 10px; 
}


.section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; 
    padding-bottom: 65px;
}

.section {
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left; 
}

.section p {
    margin: 0 0 10px 0; 
    margin-left:27px;
    padding: 0;
    font-size: 16px; 
    color: #7D97AF;
}

.custom-h2 {
    font-size: 28px;
    font-weight: 500;
    color: #546388;
    font-family: 'Montserrat', sans-serif;
}


.custom-h2-bold {
    font-weight: 1000;
    margin-bottom: 50px;
}


.sample-question {
    background-color: #ffffff;
    border: none;
    border-radius: 90px;
    color: #7D97AF;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 10px 30px;
    cursor: pointer;
    width: 100%;
    height: 70px;
    box-shadow: 4px 6px 10px rgba(85, 85, 85, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.05);
    text-align: left;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; 
    text-overflow: ellipsis;
    overflow:hidden;
}

.sample-question:hover {
    background-color: #f0f0f0; 
    color: #7D97AF;
    box-shadow: 5px 7px 12px rgba(85, 85, 85, 0.2), -3px -3px 7px rgba(0, 0, 0, 0.1); 
}


@media (max-width: 1024px) {
    .section p, .sample-question {
        font-size: 14px; 
        padding: 8px 20px; 
    }
}

@media (max-width: 768px) {
    .section-container {
        flex-direction: column;
        align-items: center;
    }

    .section {
        width: 90%;
        margin-bottom: 20px;
    }

    .sample-question {
        padding: 20px 30px;
        font-size: 16px; 
        height: auto; 
        min-height: 70px; 
        white-space: normal; 
    }

    .section p {
        font-size: 1em; 
        padding: 0 15px;
        text-align: center; 
    }

    .chat-welcome-message {
        margin-top: -35px;
        padding: 10px;
    }
}
