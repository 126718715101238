.navbar {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    background-image: linear-gradient(to right, #98DB48, #E67FD4, #935CDD);
    color: #FFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    position: relative; 
}

.navbar-text {
    z-index: 2;
    padding: 0 15px;
}

.navbar-brand {
    position: absolute;
    left: 0;
    bottom: -25px; 
    transform: translateX(-2%);
    z-index: 3;
}

.navbar-text.left, .navbar-text.right {
    text-align: left;
    margin-left: 90px;
}

.navbar-text.right {
    text-align: right;
    margin-left: auto;
    color: #D5ECFE;
    text-decoration: none;
}

.navbar-text.right:hover {
    text-decoration: underline;
}

.call-icon {
    height: 20px;
    width: 18px;
    margin-left: 5px; 
    vertical-align: middle;
}

.mobile-text {
    display: none;
}

@media (max-width: 768px) {
    .navbar {
        padding: 0px;
        padding-left: 25%; 
        display: flex; 
        align-items: center; 
        height: 50px;
        position: relative;
    }
    .desktop-text {
        display: none;
    }
    .mobile-text {
        display: block;
        text-align: left;
        font-size: 12px;
    }
    .navbar-brand {
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 20%; 
    }
}

@media (max-width: 992px) {
    .navbar-brand {
        position: absolute;
        left: -2px;
        top: 48%;
        transform: translateY(-50%);
        z-index: 3;
        width: 20%;
    }
  }
  

@media (max-width: 1500px) {
    .navbar-brand {
        position: absolute;
        left: -2px;
        top: 45%;
        transform: translateY(-50%);
        z-index: 3;
        width: 30%;
    }
  }
  
