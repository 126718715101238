.download-button-container {
    display: flex;
    justify-content: right;
    margin: 10px 0;
    background: transparent;
}

.download-button {
    position: fixed; 
    top: 135px; 
    right: 40px;
    z-index: 100; 
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.download-icon {
    width: 18px; 
    height: auto;
}

.underline {
    text-decoration: underline;
}

.chat-container {
    position: relative;
    font-family: 'Roboto', sans-serif; /* Updated font family */
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F7FAFD;
}


.messages-section {
    flex: 1;
    overflow-y: scroll; /* Keep the ability to scroll */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    scroll-behavior: smooth;
    background-color:transparent;
    
}

.messages-section::-webkit-scrollbar {
    width: 0px; /* Hide scrollbar for Chrome, Safari, Opera */
}

.messages-section::-webkit-scrollbar-track {
    background: #2f2f3f;
    border-radius: 6px;
}

.messages-section::-webkit-scrollbar-thumb {
    background-color: #444654;
    border-radius: 6px;
    border: 2px solid #2f2f3f;
}

.messages-section::-webkit-scrollbar-thumb:hover {
    background-color: #555765;
}

.bottom-section {
    background-color: #5D82A0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #DDE6EF;
    border-radius: 90px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 0 10px; /* Adjusted padding for inner spacing */
    margin-top: 30px;
}

.input-field {
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    resize: none;
    padding-right: 8%;
    overflow-y: auto;
    line-height: 25px;
    height: 25px;
    color: #5D82A0;
}

.input-field::placeholder {
    color: #587183; /* Darker Shade 1 */
}

.input-field::-webkit-scrollbar {
    display: none; /* This hides the scrollbar in WebKit browsers */
}

/* Hide scrollbar for Firefox */
.input-field {
    scrollbar-width: none; /* This hides the scrollbar in Firefox */
}

/* Optionally, for Internet Explorer and older versions of Edge */
.input-field {
    -ms-overflow-style: none; /* This can be used to hide the scrollbar in IE and older versions of Edge */
}

.user-profile-img {
    height: 42px;
    width: 42px;
    border-radius: 50%; 
    margin-right: 5px; 
    object-fit: cover; 
    border: 5px solid #FFFFFF; 
    margin-left: -13px;
}

.icon-button {
    position: absolute;    
    background: none;
    border: none;
    cursor: pointer;
    display: flex; /* Use flexbox to center the icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.send-button {
    right: 65px;    
}

.clear-button {
    right: 25px;
}


.disclaimer {
    width: 85%;
    display: flex;
    justify-content: space-between;
    color: #DDE6EF;
}

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disclaimer-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 1000;
  }
  
  .disclaimer-modal-content {
    background-color: #EBF0F5; 
    padding: 20px; 
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    width: 90%;
    max-width: 500px;
    text-align: center;
    color: #546388;
    line-height: 25px;
  }

  .disclaimer-modal-content h2 {
    text-align: center; 
  }
  
  .disclaimer-modal-button {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #546388;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 20px auto 0;
  }
  
  .disclaimer-modal-button:hover {
    background-color: #dbdbdb; /* Darker shade for hover effect */
  }

  .clickable-disclaimer {
    color: #DDE6EF; /* Bootstrap default link color for consistency */
    cursor: pointer;
    text-decoration: underline; /* Mimics a hyperlink */
  }

  .more-options-icon {
    display: none; /* Hide by default */
    position: absolute;
    right: 90px; /* Adjust based on your layout */
    cursor: pointer;
}

  

@keyframes blink {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes ellipsis {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
    100% { content: ''; }
}


@media (max-width: 768px) {

    .download-button {
        visibility: hidden;
    }
    
    .disclaimer {
        display: none;
    }

    .more-options-icon {
        display: block; /* Only show on mobile */
    }

    .chat-container,
    .messages-section,
    .input-section {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
    }

    .messages-section {
        padding-top: 60px;
    }

    .chat-container {
        padding: 10px;
    }

    .message-wrapper {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .message-content {
        width: 100%;
        margin-top: 5px;
    }

    .message {
        padding: 8px 10px;
        border-radius: 4px;
    }


    .input-section {
        flex-direction: column;
        align-items: stretch;
    }

    .bottom-section {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center children vertically */
        padding: 30px 0; /* Add some padding to ensure content doesn't touch the edges */
    }

    .input-container {
        margin: auto 0; /* Ensures margin is auto-adjusted, helping in centering */
    }

    .input-section textarea {
        flex: none;
        width: 100%;
        margin-bottom: 10px;
    }

    .input-field {
        padding-right: 25%;

    }

    .feedback-icons {
        position: static;
        margin-top: 5px;
        justify-content: flex-start;
    }

    .thumbs-wrapper {
        margin-right: 0;
        flex-direction: row-reverse;
    }

    .send-button {
        right: 50px;    
    }
    
    .clear-button {
        right: 15px;
    }

    

}


.regenerate-container {
    text-align: center; /* Center the contents */
    margin: 10px auto; /* Vertical margin for spacing */
}

.regenerate-message {
    color: white; /* White text for visibility */
    font-size: 16px; /* Adjust as needed */
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px; /* Spacing between the message and the button */
}


.regenerate-btn {
    background-color: #ffffff; /* Yellow background */
    color: #5B86A9; /* Black text */
    padding: 10px;
    border: 2px solid #EBF0F5; /* Invisible-like border, matching new border color */
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s;
    font-size: 16px; /* Maintain the font size */
    font-family: 'Roboto', sans-serif; /* Keep the font family */
    display: block;
    margin: 10px auto;
}

.regenerate-btn:hover {
    background-color: #f2f2f2; /* Slightly darker yellow on hover */
    border-color: #EBF0F5; /* Darker border on hover */
}

.regenerate-btn:active {
    transform: scale(0.98); /* Slight scale down on click */
}